<template>
  <div>
    <div class="p-d-flex p-jc-between p-ai-center">
      <h1>CRM</h1>
      <LeadForm
        :show="showLeadForm"
        v-on:cancel="showLeadForm = false"
        v-on:createdLead="createdLead"
      />
      <div class="">
        <Button
          label="Criar Lead"
          icon="pi pi-plus"
          class="p-button-raised p-button-text p-button-secondary"
          @click="showLeadForm = true"
        />
        <Button
          v-if="crmView == 'streak'"
          label="Trello"
          icon="pi pi-arrow-right-arrow-left"
          class="p-button-raised p-button-text p-button-secondary"
          @click="setCrmView('trello')"
        />
        <Button
          v-else
          label="Streak"
          icon="pi pi-arrow-right-arrow-left"
          class="p-button-raised p-button-text p-button-secondary"
          @click="setCrmView('streak')"
        />
        <Button
          v-if="can('seeCRMGraphics')"
          label="Gráficos"
          icon="pi pi-chart-bar"
          class="p-button-raised p-button-text p-button-secondary"
          @click="$router.push(`/crm/graphics`)"
        />
      </div>
    </div>
    <CrmTrello v-if="crmView == 'trello'" />
    <CrmStreak v-else />
  </div>
</template>

<script>
import CrmTrello from "./crmTrello.vue";
import CrmStreak from "./crmStreak.vue";
import LeadForm from "../components/crm/LeadForm.vue";
export default {
  name: "CRM",
  components: {
    CrmTrello,
    CrmStreak,
    LeadForm,
  },
  data() {
    return {
      crmView: localStorage.crmView || "streak",
      showLeadForm: false,
    };
  },
  watch: {
    crmView(newView) {
      localStorage.crmView = newView;
    },
  },
  methods: {
    setCrmView(view) {
      this.crmView = view;
    },
    createdLead() {
      return window.location.reload();
    },
  },
};
</script>
