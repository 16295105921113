<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw', position: 'relative' }"
      :header="'Alterar Estado da Lead'"
      :modal="true"
      :closable="false"
    >
      <form
        name="crmProposalsChangeStatus"
        class="p-col-12"
        @submit.prevent="setStatus"
        style="min-height: 15vh"
      >
        <Loading
          :active.sync="showLoader"
          :isFullPage="false"
          :zIndex="9000"
        ></Loading>
        <p v-if="!selected">
          <b>Lead {{ proposalId }} | {{ proposalSubject }}</b>
        </p>
        <p
          v-else
          v-for="proposalSelected in selected"
          :key="proposalSelected.id"
        >
          <b>Lead {{ proposalSelected.id }} | {{ proposalSelected.subject }}</b>
        </p>
        <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="proposalStatus"
                :options="statusList"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'id'"
                v-model="current"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('proposalStatus') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="proposalStatus">Selecionar Estado da Lead</label>
            </span>
            <small
              v-if="errors.has('proposalStatus')"
              class="p-error"
              role="alert"
            >
              Estado é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setStatus"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import crmService from "../../services/crm.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "CrmChangeStatus",
  components: {
    Loading,
  },
  props: [
    "showToast",
    "show",
    "statusId",
    "proposalId",
    "proposalSubject",
    "selected",
  ],
  watch: {
    statusId() {
      this.showLoader = false;
      this.$validator.pause();
      this.$validator.reset();
      this.current =
        !this.selected || this.selected == undefined ? this.statusId : null;
    },
  },
  data() {
    return {
      current: !this.selected ? this.statusId : null,
      statusList: [],
      showLoader: false,
    };
  },
  created() {
    this.getStatusList();
  },
  methods: {
    getStatusList() {
      return crmService
        .getStatusList()
        .then((response) => (this.statusList = response));
    },
    cancel() {
      return this.$emit("cancel");
    },
    setStatus() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        if (this.selected != null) {
          return this.setStatusMultipleProposals();
        }
        this.showLoader = true;
        return crmService
          .setStatus(this.proposalId, { status_id: parseInt(this.current) })
          .then((response) => {
            this.showLoader = false;
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao alterar o estado da proposta",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("changedProposalStatus", {
                status: "error",
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Estado da proposta alterada com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("changedProposalStatus", {
              status: "ok",
              result: response,
            });
          });
      });
    },
    async setStatusMultipleProposals() {
      let noErrors = true;
      let res = null;
      this.showLoader = true;
      for (const proposal of this.selected) {
        await crmService
          .setStatus(proposal.id, { status_id: parseInt(this.current) })
          .then((response) => {
            if (!response) {
              return (noErrors = false);
            }
            res = response;
          });
      }
      this.showLoader = false;
      if (!noErrors) {
        if (this.showToast) {
          this.$toast.add({
            severity: "error",
            summary: "Erro ao alterar o estado da lead",
            detail: "",
            life: 3000,
          });
        }
        return this.$emit("changedProposalStatus", {
          status: "error",
        });
      }

      if (this.showToast) {
        this.$toast.add({
          severity: "success",
          summary: `Estado da lead alterada com sucesso`,
          detail: ``,
          life: 3000,
        });
      }
      return this.$emit("changedProposalStatus", {
        status: "ok",
        result: res,
      });
    },
  },
};
</script>
