<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <div class="p-grid p-fluid dashboard p-jc-center">
      <W4Mstreak
        :states="streakStatus"
        :loader="streakLoading"
        v-on:W4MStreakChangeSelected="streakSelectedChange"
      />
    </div>
    <ChangePriority
      :showToast="true"
      :show="proposalChangePriority.show"
      :proposalId="proposalChangePriority.proposalId"
      :proposalSubject="proposalChangePriority.proposalSubject"
      :priorityId="proposalChangePriority.priorityId"
      :selected="proposalChangePriority.selected"
      v-on:cancel="changePriorityCancel"
      v-on:changedProposalPriority="changePriorityOk"
    />
    <ChangeSeller
      :showToast="true"
      :show="proposalChangeSeller.show"
      :proposalId="proposalChangeSeller.proposalId"
      :proposalSubject="proposalChangeSeller.proposalSubject"
      :sellerId="proposalChangeSeller.sellerId"
      :selected="proposalChangeSeller.selected"
      v-on:cancel="changeSellerCancel"
      v-on:changedProposalSeller="changeSellerOk"
    />
    <ChangeClient
      :showToast="true"
      :show="proposalChangeClient.show"
      :proposalId="proposalChangeClient.proposalId"
      :proposalSubject="proposalChangeClient.proposalSubject"
      :clientTypeId="proposalChangeClient.clientTypeId"
      :clientName="proposalChangeClient.potential_client_name"
      :clientPerson="proposalChangeClient.potential_client_person"
      :clientTelephone="proposalChangeClient.potential_client_telephone"
      :clientEmail="proposalChangeClient.potential_client_email"
      :clientComments="proposalChangeClient.potential_client_comments"
      :clientContactId="proposalChangeClient.contactId"
      :selected="proposalChangeClient.selected"
      v-on:cancel="changeClientCancel"
      v-on:changedClientType="changeClientOk"
    />
    <ChangeStatus
      :showToast="true"
      :show="proposalChangeStatus.show"
      :proposalId="proposalChangeStatus.proposalId"
      :proposalSubject="proposalChangeStatus.proposalSubject"
      :statusId="proposalChangeStatus.statusId"
      :selected="proposalChangeStatus.selected"
      v-on:cancel="changeStatusCancel"
      v-on:changedProposalStatus="changeStatusOk"
    />
    <ChangeClosingProbability
      :showToast="true"
      :show="proposalClosingProbability.show"
      :proposalId="proposalClosingProbability.proposalId"
      :proposalSubject="proposalClosingProbability.proposalSubject"
      :closingProbability="proposalClosingProbability.probability"
      :selected="proposalClosingProbability.selected"
      v-on:cancel="changeClosingProbabilityCancel"
      v-on:changedProposalClosingProbability="changeClosingProbabilityOk"
    />
    <ChangeNextContact
      :showToast="true"
      :show="proposalNextContact.show"
      :proposalId="proposalNextContact.proposalId"
      :proposalSubject="proposalNextContact.proposalSubject"
      :nextContact="proposalNextContact.nextContact"
      :selected="proposalNextContact.selected"
      v-on:cancel="changeNextContactCancel"
      v-on:changedNextContact="changeNextContactOk"
    />
    <MergeLeads
      :showToast="true"
      :show="showMergeLead"
      :selected="selectedProposals"
      v-on:cancel="showMergeLead = false"
      v-on:mergedLeads="mergedLeadsOk"
    />
    <div style="height: 65vh">
      <DataTable
        :value="proposals"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="status"
        responsiveLayout="scroll"
        rowGroupMode="subheader"
        removableSort
        groupRowsBy="proposal_status.name"
        :expandableRowGroups="true"
        :expandedRowGroups.sync="expandedRowGroups"
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :selection.sync="selectedProposals"
      >
        <template #header>
          <div class="table-header">
            <Button
              icon="pi pi-sync"
              class="p-button-sm p-mr-1"
              label="Mostrar todos as leads"
              @click="showAllLeads()"
            />
            <Button
              v-if="selectedProposals && selectedProposals.length > 1"
              icon="pi pi-sitemap"
              class="p-button-sm p-mr-1"
              label="Merge Leads"
              @click="showMergeLead = true"
            />
            <!--  <span style="position: absolute; right: 10px">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="arquiveSearch"
                  type="text"
                  placeholder="Pesquisar"
                  class="p-inputtext-sm"
                  @keyup.enter="searchProposals"
                />
              </span>
            </span>-->
          </div>
        </template>
        <Column
          selectionMode="multiple"
          :headerStyle="{ 'min-width': '3%', 'max-width': '3%' }"
          :filterHeaderStyle="{ 'min-width': '3%', 'max-width': '3%' }"
          :bodyStyle="{ 'min-width': '3%', 'max-width': '3%' }"
        ></Column>
        <template #empty>
          Nada a mostrar. Selecione no menu em cima o que pretende visualizar.
        </template>
        <template #loading> A carregar dados. Por favor Aguarde... </template>
        <template #groupheader="slotProps">
          <div
            class="myGroupHeader"
            :style="getGroupheaderColor(slotProps.data)"
          >
            {{ slotProps.data.proposal_status.name }}
          </div>
        </template>
        <Column
          sortable
          field="id"
          header="Id"
          filterField="id"
          :headerStyle="{ 'min-width': '6%', 'max-width': '6%' }"
          :filterHeaderStyle="{ 'min-width': '6%', 'max-width': '6%' }"
          :bodyStyle="{ 'min-width': '6%', 'max-width': '6%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Ver Proposta'"
              @click="viewProposal(slotProps.data.id)"
              >{{ slotProps.data.id }}</span
            >
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="cleanSubject"
          header="Assunto"
          filterField="cleanSubject"
          :headerStyle="{ 'min-width': '21%', 'max-width': '21%' }"
          :filterHeaderStyle="{ 'min-width': '21%', 'max-width': '21%' }"
          :bodyStyle="{ 'min-width': '21%', 'max-width': '21%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.cleanSubject }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="status"
          header="Estado"
          :headerStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :filterHeaderStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :bodyStyle="{ 'min-width': '8%', 'max-width': '8%' }"
        >
          <template #body="slotProps">
            <i
              class="pi pi-bookmark-fill streak-icon"
              :style="getIconColor(slotProps.data.proposal_status.name)"
            ></i>
            <span
              v-if="
                loggedUser.isEsa ||
                loggedUser.isAdmin ||
                loggedUser.id == slotProps.data.assigned_to ||
                !slotProps.data.assigned_to
              "
              class="clickable-item"
              v-tooltip="'Alterar Estado'"
              @click="changeStatus(slotProps.data)"
            >
              {{ slotProps.data.proposal_status.name }}
            </span>
            <span v-else>
              {{ slotProps.data.proposal_status.name }}
            </span>
          </template>
        </Column>
        <!-- <Column
          sortable
          field="priority"
          header="Prioridade"
          filterField="priority"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Alterar Prioridade'"
              @click="changePriority(slotProps.data)"
            >
              {{ slotProps.data.proposal_priority.name }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="priorityList"
              optionValue="priority"
              optionLabel="name"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column> -->
        <Column
          header="Valores (Venda / Mensalidade / Custo)"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{
            'min-width': '10%',
            'max-width': '10%',
            'text-align': 'center',
            display: 'table-cell',
          }"
          :headerClass="'align-center'"
        >
          <template #body="slotProps">
            {{
              toEuro(slotProps.data.sale_value ? slotProps.data.sale_value : 0)
            }}
            /
            {{
              toEuro(
                slotProps.data.monthly_sales_value
                  ? slotProps.data.monthly_sales_value
                  : 0
              )
            }}
            /
            {{ toEuro(slotProps.data.cost ? slotProps.data.cost : 0) }}
          </template>
        </Column>
        <Column
          sortable
          field="closing_probability"
          header="Prob. Fecho"
          filterField="closing_probability"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Alterar Probabilidade de Fecho'"
              @click="changeClosingProbability(slotProps.data)"
            >
              {{ slotProps.data.closing_probability }} %
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="priorityCloseList"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          assun
          field="client_type"
          header="Tipo Cliente"
          filterField="client_type"
          :headerStyle="{ 'min-width': '12%', 'max-width': '12%' }"
          :filterHeaderStyle="{ 'min-width': '12%', 'max-width': '12%' }"
          :bodyStyle="{ 'min-width': '12%', 'max-width': '12%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Alterar Tipo de Cliente'"
              @click="changeClient(slotProps.data)"
            >
              {{
                !slotProps.data.client_type
                  ? "_______"
                  : slotProps.data.type_client.name
              }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="clientTypeList"
              optionValue="id"
              optionLabel="name"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column>
        <!-- <Column
          sortable
          field="contactName"
          header="Contacto"
          filterField="contactName"
          :headerStyle="{ 'min-width': '11%', 'max-width': '11%' }"
          :filterHeaderStyle="{ 'min-width': '11%', 'max-width': '11%' }"
          :bodyStyle="{ 'min-width': '11%', 'max-width': '11%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Alterar Contacto'"
              @click="changeClient(slotProps.data)"
            >
              {{
                slotProps.data.contactName.trim() == ""
                  ? "_______"
                  : slotProps.data.contactName
              }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column> -->
        <Column
          sortable
          field="nextContact"
          header="Próximo Contacto"
          filterField="nextContact"
          dataType="date"
          :headerStyle="{ 'min-width': '11%', 'max-width': '11%' }"
          :filterHeaderStyle="{ 'min-width': '11%', 'max-width': '11%' }"
          :bodyStyle="{ 'min-width': '11%', 'max-width': '11%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Alterar Próximo Contacto'"
              @click="changeNextContact(slotProps.data)"
              >{{
                slotProps.data.nextContact
                  ? getDateTime(slotProps.data.nextContact)
                  : "_______"
              }}</span
            >
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
        <Column
          sortable
          field="seller_name"
          header="Vendedor"
          filterField="seller_name"
          :headerStyle="{ 'min-width': '11%', 'max-width': '11%' }"
          :filterHeaderStyle="{ 'min-width': '11%', 'max-width': '11%' }"
          :bodyStyle="{ 'min-width': '11%', 'max-width': '11%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Alterar Vendedor'"
              @click="changeSeller(slotProps.data)"
              >{{
                slotProps.data.seller && slotProps.data.seller_name.trim() != ""
                  ? slotProps.data.seller_name
                  : "_______"
              }}</span
            >
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="createdDaysAgo"
          header="Criado"
          :headerStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :filterHeaderStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :bodyStyle="{ 'min-width': '8%', 'max-width': '8%' }"
        >
          <template #body="slotProps">
            <span>{{ slotProps.data.createdDaysAgo }} dias</span>
          </template>
        </Column>
        <!-- <Column
          sortable
          field="created_at"
          header="Criado"
          filterField="created_at"
          dataType="date"
          :headerStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :filterHeaderStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :bodyStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
        >
          <template #body="slotProps">
            <span>{{ getDate(slotProps.data.created_at) }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column> -->
      </DataTable>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api/";
import { getOnlyDate, getOnlyTime, formatNumber } from "../helpers/helpers";
import crmService from "../services/crm.service";
import W4Mstreak from "../components/W4Mstreak/W4Mstreak.vue";
import ChangePriority from "../components/crm/ChangePriority.vue";
import ChangeClient from "../components/crm/ChangeClient.vue";
import ChangeStatus from "../components/crm/ChangeStatus.vue";
import ChangeClosingProbability from "../components/crm/ChangeClosingProbability.vue";
import ChangeNextContact from "../components/crm/ChangeNextContact.vue";
import ChangeSeller from "../components/crm/ChangeSeller.vue";
import MergeLeads from "../components/crm/MergeLeads.vue";

export default {
  name: "CrmStreak",
  components: {
    W4Mstreak,
    ChangePriority,
    ChangeClient,
    ChangeStatus,
    ChangeClosingProbability,
    ChangeNextContact,
    ChangeSeller,
    MergeLeads,
  },
  data() {
    return {
      streakStatus: [],
      streakIndicators: [],
      priorityList: [],
      clientTypeList: [],
      currentState: {},
      expandedRowGroups: [],
      proposals: [],
      showAll: false,
      showMergeLead: false,
      loading: false,
      streakLoading: false,
      selectedProposals: null,
      filters: {
        id: { value: null, matchMode: FilterMatchMode.CONTAINS },
        cleanSubject: { value: null, matchMode: FilterMatchMode.CONTAINS },
        contactName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.CONTAINS },
        priority: { value: null, matchMode: FilterMatchMode.CONTAINS },
        seller_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        created_at: { value: null, matchMode: FilterMatchMode.DATE_IS },
        nextContact: { value: null, matchMode: FilterMatchMode.DATE_IS },
        closing_probability: { value: null, matchMode: FilterMatchMode.EQUALS },
        client_type: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      proposalChangePriority: {
        show: false,
        proposalId: null,
        proposalSubject: null,
        priorityId: null,
      },
      proposalChangeSeller: {
        show: false,
        proposalId: null,
        proposalSubject: null,
        sellerId: null,
      },
      proposalChangeClient: {
        show: false,
        proposalId: null,
        proposalSubject: null,
        clientTypeId: null,
        contactId: null,
        potential_client_name: null,
        potential_client_person: null,
        potential_client_telephone: null,
        potential_client_email: null,
        potential_client_comments: null,
      },
      proposalClosingProbability: {
        show: false,
        proposalId: null,
        proposalSubject: null,
        probability: null,
      },
      proposalChangeStatus: {
        show: false,
        proposalId: null,
        proposalSubject: null,
        statusId: null,
      },
      proposalNextContact: {
        show: false,
        proposalId: null,
        proposalSubject: null,
        nextContact: null,
      },
      priorityCloseList: [
        { label: "0%", value: 0 },
        { label: "15%", value: 15 },
        { label: "25%", value: 25 },
        { label: "50%", value: 50 },
        { label: "75%", value: 75 },
        { label: "90%", value: 90 },
        { label: "100%", value: 100 },
      ],
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  async beforeCreate() {},
  async created() {
    this.loading = true;
    //this.priorityList = await crmService.getPriorityList();
    this.clientTypeList = await crmService.getClientTypeList();
    await this.initStreak();
    this.loading = false;
  },
  methods: {
    async initStreak() {
      this.streakLoading = true;
      this.streakStatus = [
        {
          backgroudColor: "#5F78DA",
          textColor: "#000",
          title: "Por Classificar",
          counter: 0,
          active: false,
        },
        {
          backgroudColor: "#00C8B6",
          textColor: "#000",
          title: "Contactar Cliente",
          counter: 0,
          active: false,
        },
        {
          backgroudColor: "#dcf900",
          textColor: "#000",
          title: "Reunião Marcada",
          counter: 0,
          active: false,
        },
        {
          backgroudColor: "#FCC200",
          textColor: "#000",
          title: "Espera Resposta",
          counter: 0,
          active: false,
        },
        // {
        //   backgroudColor: "#ffdb72",
        //   textColor: "#000",
        //   title: "Pendente",
        //   counter: 0,
        //   active: false,
        // },
        {
          backgroudColor: "#86e053",
          textColor: "#000",
          title: "Vendido Sem PO",
          counter: 0,
          active: false,
        },
        {
          backgroudColor: "#56dd54",
          textColor: "#000",
          title: "Projeto",
          counter: 0,
          active: false,
        },
        {
          backgroudColor: "#e03d23",
          textColor: "#000",
          title: "Não Vendido",
          counter: 0,
          active: false,
        },
        {
          backgroudColor: "#855fd8",
          textColor: "#000",
          title: "Piloto",
          counter: 0,
          active: false,
        },
      ];

      this.streakStatus = [
        {
          backgroudColor: "#5F78DA",
          textColor: "#000",
          title: "Por Classificar",
          counter: await crmService.countByStatus(1),
          active: false,
        },
        {
          backgroudColor: "#00C8B6",
          textColor: "#000",
          title: "Contactar Cliente",
          counter: await crmService.countByStatus(2),
          active: false,
        },
        {
          backgroudColor: "#dcf900",
          textColor: "#000",
          title: "Reunião Marcada",
          counter: await crmService.countByStatus(9),
          active: false,
        },
        {
          backgroudColor: "#FCC200",
          textColor: "#000",
          title: "Espera Resposta",
          counter: await crmService.countByStatus(3),
          active: false,
        },
        // {
        //   backgroudColor: "#ffdb72",
        //   textColor: "#000",
        //   title: "Pendente",
        //   counter: await crmService.countByStatus(4),
        //   active: false,
        // },
        {
          backgroudColor: "#86e053",
          textColor: "#000",
          title: "Vendido Sem PO",
          counter: await crmService.countByStatus(6),
          active: false,
        },
        {
          backgroudColor: "#56dd54",
          textColor: "#000",
          title: "Projeto",
          counter: await crmService.countByStatus(8),
          active: false,
        },
        {
          backgroudColor: "#e03d23",
          textColor: "#000",
          title: "Não Vendido",
          counter: await crmService.countByStatus(7),
          active: false,
        },
        {
          backgroudColor: "#855fd8",
          textColor: "#000",
          title: "Piloto",
          counter: await crmService.countByStatus(5),
          active: false,
        },
      ];
      return (this.streakLoading = false);
    },
    streakSelectedChange(selected, notRemove) {
      this.currentState = selected;
      this.currentState.notRemove = notRemove;
      switch (selected.currentState) {
        case "Por Classificar":
          this.getByStatus(selected.currentStateIndex, 1, notRemove);
          break;
        case "Contactar Cliente":
          this.getByStatus(selected.currentStateIndex, 2, notRemove);
          break;
        case "Espera Resposta":
          this.getByStatus(selected.currentStateIndex, 3, notRemove);
          break;
        case "Pendente":
          this.getByStatus(selected.currentStateIndex, 4, notRemove);
          break;
        case "Piloto":
          this.getByStatus(selected.currentStateIndex, 5, notRemove);
          break;
        case "Vendido Sem PO":
          this.getByStatus(selected.currentStateIndex, 6, notRemove);
          break;
        case "Não Vendido":
          this.getByStatus(selected.currentStateIndex, 7, notRemove);
          break;
        case "Projeto":
          this.getByStatus(selected.currentStateIndex, 8, notRemove);
          break;
        case "Reunião Marcada":
          this.getByStatus(selected.currentStateIndex, 9, notRemove);
          break;
        default:
          return;
      }
      return this.expandedRowGroups.push(selected.currentState);
    },
    async getByStatus(streakIndex, status, notRemove) {
      this.loading = true;
      let statusProposal = await crmService.getByStatus(status);
      statusProposal = statusProposal.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        created_at: new Date(`${v.created_at}`),
        updated_at: new Date(`${v.updated_at}`),
        client_name: v.client ? v.client.name : null,
        seller_name: v.seller ? v.seller.name : null,
        type_name: v.type ? v.type.name : null,
        contactName: v.contact_id
          ? v.contact.name
          : `${
              v.potential_client_name ? v.potential_client_name + " | " : ""
            } ${v.potential_client_person ? v.potential_client_person : ""}`,
        nextContact: v.next_contact ? new Date(`${v.next_contact}`) : null,
      }));
      if (notRemove != undefined && notRemove == true) {
        this.proposals = this.proposals.concat(statusProposal);
      } else {
        this.showAll = false;
        this.proposals = statusProposal;
      }
      return (this.loading = false);
    },
    getGroupheaderColor(info) {
      let statusInfo = this.streakStatus.find(
        (status) => status.title == info.proposal_status.name
      );
      if (!statusInfo) {
        return {};
      }
      return {
        backgroundColor: statusInfo.backgroudColor,
        borderColor: statusInfo.backgroudColor,
        color: statusInfo.textColor,
      };
    },
    getIconColor(info) {
      let statusInfo = this.streakStatus.find((status) => status.title == info);
      return { color: statusInfo.backgroudColor };
    },
    viewProposal(proposalId) {
      let route = this.$router.resolve({
        path: `/crm/lead/${proposalId}`,
      });
      window.open(route.href, "_blank");
    },
    getDateTime(date) {
      return `${getOnlyDate(date)} ${getOnlyTime(date)}`;
    },
    getDate(date) {
      return `${getOnlyDate(date)}`;
    },
    toEuro(value) {
      return `${formatNumber(value)}`;
    },
    streakStatusChange(from, to) {
      let statusFrom = this.streakStatus.find((status) => status.title == from);
      if (statusFrom) {
        statusFrom.counter = --statusFrom.counter;
      }
      if (to == "") {
        return;
      }
      let statusTo = this.streakStatus.find((status) => status.title == to);
      if (statusTo) {
        statusTo.counter = ++statusTo.counter;
      }
    },
    removeByStatus(status) {
      this.proposals.forEach((proposal, index, object) => {
        if (proposal.proposal_status.name === status) {
          object.splice(index, 1);
        }
      });
    },
    changePriority(proposal) {
      return (this.proposalChangePriority = {
        show: true,
        proposalId: proposal.id,
        proposalSubject: proposal.cleanSubject,
        priorityId: proposal.priority,
        proposal: proposal,
        selected:
          this.selectedProposals && this.selectedProposals.length > 1
            ? this.selectedProposals
            : null,
      });
    },
    changePriorityCancel() {
      return (this.proposalChangePriority = {
        show: false,
        proposalId: null,
        proposalSubject: null,
        priorityId: null,
      });
    },
    changePriorityOk(response) {
      if (response.status == "ok") {
        if (this.proposalChangePriority.selected) {
          for (var proposal of this.selectedProposals) {
            proposal.priority = response.result.priority_id;
            proposal.proposal_priority = {
              priority: response.result.priority_id,
              name: response.result.priority_name,
            };
          }
        } else {
          this.proposalChangePriority.proposal.priority =
            response.result.priority_id;
          this.proposalChangePriority.proposal.proposal_priority = {
            priority: response.result.priority_id,
            name: response.result.priority_name,
          };
        }
      }
      return this.changePriorityCancel();
    },
    changeStatus(proposal) {
      return (this.proposalChangeStatus = {
        show: true,
        proposalId: proposal.id,
        proposalSubject: proposal.cleanSubject,
        statusId: proposal.status,
        proposal: proposal,
        selected:
          this.selectedProposals && this.selectedProposals.length > 1
            ? this.selectedProposals
            : null,
      });
    },
    changeStatusCancel() {
      this.selectedProposals = null;
      return (this.proposalChangeStatus = {
        show: false,
        proposalId: null,
        proposalSubject: null,
        statusId: null,
      });
    },
    changeStatusOk(response) {
      if (response.status == "ok") {
        if (this.proposalChangeStatus.selected) {
          for (var proposal of this.selectedProposals) {
            this.streakStatusChange(
              proposal.proposal_status.name,
              response.result.status_name
            );
            proposal.status = response.result.status_id;
            proposal.proposal_status = {
              status: response.result.status_id,
              name: response.result.status_name,
            };
            if (!this.showAll) {
              this.removeByStatus(response.result.status_name);
            }
          }
        } else {
          this.streakStatusChange(
            this.proposalChangeStatus.proposal.proposal_status.name,
            response.result.status_name
          );
          this.proposalChangeStatus.proposal.status = response.result.status_id;
          this.proposalChangeStatus.proposal.proposal_status = {
            status: response.result.status_id,
            name: response.result.status_name,
          };
          if (!this.showAll) {
            this.removeByStatus(response.result.status_name);
          }
        }
      }
      return this.changeStatusCancel();
    },
    changeClosingProbability(proposal) {
      return (this.proposalClosingProbability = {
        show: true,
        proposalId: proposal.id,
        proposalSubject: proposal.cleanSubject,
        probability: proposal.closing_probability,
        proposal: proposal,
        selected:
          this.selectedProposals && this.selectedProposals.length > 1
            ? this.selectedProposals
            : null,
      });
    },
    changeClosingProbabilityCancel() {
      return (this.proposalClosingProbability = {
        show: false,
        proposalId: null,
        proposalSubject: null,
        probability: null,
      });
    },
    changeClosingProbabilityOk(response) {
      if (response.status == "ok") {
        if (this.proposalClosingProbability.selected) {
          for (var proposal of this.selectedProposals) {
            proposal.closing_probability = response.result.closing_probability;
          }
        } else {
          this.proposalClosingProbability.proposal.closing_probability =
            response.result.closing_probability;
        }
      }
      return this.changeClosingProbabilityCancel();
    },
    changeClient(proposal) {
      return (this.proposalChangeClient = {
        show: true,
        proposalId: proposal.id,
        proposalSubject: proposal.cleanSubject,
        clientTypeId: proposal.client_type,
        contactId: proposal.contact_id,
        potential_client_name: proposal.potential_client_name,
        potential_client_person: proposal.potential_client_person,
        potential_client_telephone: proposal.potential_client_telephone,
        potential_client_email: proposal.potential_client_email,
        potential_client_comments: proposal.potential_client_comments,
        proposal: proposal,
        selected:
          this.selectedProposals && this.selectedProposals.length > 1
            ? this.selectedProposals
            : null,
      });
    },
    changeClientCancel() {
      return (this.proposalChangeClient = {
        show: false,
        proposalId: null,
        proposalSubject: null,
        clientTypeId: null,
        contactId: null,
        potential_client_name: null,
        potential_client_person: null,
        potential_client_telephone: null,
        potential_client_email: null,
        potential_client_comments: null,
      });
    },
    changeClientOk(response) {
      if (response.status == "ok") {
        if (this.proposalChangeClient.selected) {
          for (var proposal of this.selectedProposals) {
            proposal.potential_client_comments =
              response.result.potential_client_comments;
            proposal.potential_client_email =
              response.result.potential_client_email;
            proposal.potential_client_name =
              response.result.potential_client_name;
            proposal.potential_client_person =
              response.result.potential_client_person;
            proposal.potential_client_telephone =
              response.result.potential_client_telephone;
            proposal.client_type = response.result.client_type;
            proposal.type_client = response.result.type_client;
            proposal.contact_id = response.result.contact_id;
            proposal.contact = response.result.contact;

            proposal.contactName = response.result.contact_id
              ? response.result.contact.name
              : `${
                  response.result.potential_client_name
                    ? response.result.potential_client_name + " | "
                    : ""
                } ${
                  response.result.potential_client_person
                    ? response.result.potential_client_person
                    : ""
                }`;
          }
        } else {
          this.proposalChangeClient.proposal.potential_client_comments =
            response.result.potential_client_comments;
          this.proposalChangeClient.proposal.potential_client_email =
            response.result.potential_client_email;
          this.proposalChangeClient.proposal.potential_client_name =
            response.result.potential_client_name;
          this.proposalChangeClient.proposal.potential_client_person =
            response.result.potential_client_person;
          this.proposalChangeClient.proposal.potential_client_telephone =
            response.result.potential_client_telephone;

          this.proposalChangeClient.proposal.client_type =
            response.result.client_type;
          this.proposalChangeClient.proposal.type_client =
            response.result.type_client;
          this.proposalChangeClient.proposal.contact_id =
            response.result.contact_id;
          this.proposalChangeClient.proposal.contact = response.result.contact;

          this.proposalChangeClient.proposal.contactName = response.result
            .contact_id
            ? response.result.contact.name
            : `${
                response.result.potential_client_name
                  ? response.result.potential_client_name + " | "
                  : ""
              } ${
                response.result.potential_client_person
                  ? response.result.potential_client_person
                  : ""
              }`;
        }
      }
      return this.changeClientCancel();
    },
    changeNextContact(proposal) {
      return (this.proposalNextContact = {
        show: true,
        proposalId: proposal.id,
        proposalSubject: proposal.cleanSubject,
        nextContact: proposal.next_contact,
        proposal: proposal,
        selected:
          this.selectedProposals && this.selectedProposals.length > 1
            ? this.selectedProposals
            : null,
      });
    },
    changeNextContactCancel() {
      return (this.proposalNextContact = {
        show: false,
        proposalId: null,
        proposalSubject: null,
        nextContact: null,
      });
    },
    changeNextContactOk(response) {
      if (response.status == "ok") {
        if (this.proposalNextContact.selected) {
          for (var proposal of this.selectedProposals) {
            proposal.next_contact = response.result.next_contact;
            proposal.nextContact = response.result.next_contact
              ? new Date(`${response.result.next_contact}`)
              : null;
          }
        } else {
          this.proposalNextContact.proposal.next_contact =
            response.result.next_contact;
          this.proposalNextContact.proposal.nextContact = response.result
            .next_contact
            ? new Date(`${response.result.next_contact}`)
            : null;
        }
      }
      return this.changeNextContactCancel();
    },
    changeSeller(proposal) {
      return (this.proposalChangeSeller = {
        show: true,
        proposalId: proposal.id,
        proposalSubject: proposal.cleanSubject,
        sellerId: proposal.seller_id,
        proposal: proposal,
        selected:
          this.selectedProposals && this.selectedProposals.length > 1
            ? this.selectedProposals
            : null,
      });
    },
    changeSellerCancel() {
      return (this.proposalChangeSeller = {
        show: false,
        proposalId: null,
        proposalSubject: null,
        sellerId: null,
      });
    },
    changeSellerOk(response) {
      if (response.status == "ok") {
        if (this.proposalChangeSeller.selected) {
          for (var proposal of this.selectedProposals) {
            proposal.seller = response.result.seller;
            proposal.seller_name = response.result.seller
              ? response.result.seller.name
              : null;
            proposal.seller_id = response.result.seller_id;
          }
        } else {
          this.proposalChangeSeller.proposal.seller = response.result.seller;
          this.proposalChangeSeller.proposal.seller_name = response.result
            .seller
            ? response.result.seller.name
            : null;
          this.proposalChangeSeller.proposal.seller_id =
            response.result.seller_id;
        }
      }
      return this.changeSellerCancel();
    },
    async showAllLeads() {
      this.showAll = true;
      this.proposals = [];
      for (const [index, status] of this.streakStatus.entries()) {
        status.loaded = false;
        await this.streakSelectedChange(
          {
            currentStateIndex: index,
            currentState: status.title,
          },
          true
        );
      }
    },
    async mergedLeadsOk(response) {
      if (response.status == "ok") {
        this.showMergeLead = false;
        await this.initStreakStatus();
        this.streakSelectedChange(
          this.currentState,
          this.currentState.notRemove
        );
      } else {
        this.showMergeLead = false;
      }
    },
  },
};
</script>
<style>
.p-column-filter {
  width: 100%;
}

.p-tooltip {
  z-index: 5;
}
.p-datatable .p-datatable-loading-overlay {
  z-index: 5;
}

.myGroupHeader {
  display: inline-flex;
  height: 25px;
  padding: 0 15px;
  align-items: center;
  font-weight: bold;
  border-radius: 25px;
  margin-left: 15px;
  cursor: default;
}

.p-datatable-scrollable .p-datatable-thead {
  z-index: 2 !important;
}

.clickable-item {
  cursor: pointer;
  color: #000;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}

.streak-icon {
  transform: rotate(90deg);
}

.p-dialog .p-dialog-header-icons {
  display: none !important;
}

.align-center .p-column-header-content {
  justify-content: center !important;
}
th.align-center {
  justify-content: center !important;
}
</style>
