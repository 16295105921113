<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <div class="p-grid">
      <div class="p-field p-col-12 p-md-2">
        <span class="p-float-label">
          <InputText
            name="subject-search"
            v-model="subjectSearch"
            v-bind:class="['form-control']"
            @change="searchBySubject"
          />
          <label for="subject-search">Pequisar Assunto</label>
        </span>
      </div>
      <div class="p-field p-col-12 p-md-2" v-if="can('SeeAllCrmProposals')">
        <span class="p-float-label">
          <InputText
            name="seller-search"
            v-model="sellerSearch"
            v-bind:class="['form-control']"
            @change="searchBySeller"
          />
          <label for="seller-search">Pequisar Vendedor</label>
        </span>
      </div>
    </div>
    <div :style="{ position: 'relative', height: '60vh' }" v-if="loading">
      <Loading :active="loading" :isFullPage="false"></Loading>
    </div>
    <div class="main">
      <div
        v-for="status in leadsStatusFilters"
        :key="status.index"
        class="status-list"
      >
        <Draggable
          :id="status.index"
          :index="status.index"
          :list="status.leads"
          class="list-group"
          draggable=".trello-item"
          group="trello"
          :style="getGroupBackgroudColor(status.index)"
          @end="onMovedEnd"
        >
          <div
            slot="header"
            role="group"
            class="status-header p-text-center"
            :style="getGroupheaderColor(status.index)"
          >
            <span>{{ status.title }}</span>
          </div>
          <span>
            <span class="p-d-flex p-jc-between"
              ><span class="p-ml-2">Total Vendas</span>
              <span class="p-mr-4">{{
                toEuro(sumSales(status.leads))
              }}</span></span
            >
            <span class="p-d-flex p-jc-between"
              ><span class="p-ml-2">Total Mensalidade</span>
              <span class="p-mr-4">{{
                toEuro(sumMonthly(status.leads))
              }}</span></span
            >
            <span class="p-d-flex p-jc-between"
              ><span class="p-ml-2">Total Custo</span>
              <span class="p-mr-4">{{
                toEuro(sumCost(status.leads))
              }}</span></span
            >
            <span class="p-d-flex p-jc-between"
              ><span class="p-ml-2">Total Leads</span>
              <span class="p-mr-4">{{ status.leads.length }} </span></span
            >
          </span>
          <divider />
          <div
            class="trello-item p-m-flex"
            v-for="lead in status.leads"
            :key="lead.id"
            :id="lead.id"
          >
            <span class="p-d-flex p-jc-center p-text-center">
              <b>{{ lead.cleanSubject }}</b>
            </span>
            <divider />
            <div class="p-m-flex">
              <div class="p-d-flex p-flex-column" style="width: 100%">
                <span v-if="lead.sale_value" class="p-d-flex p-jc-between">
                  <span>Venda</span> <span>{{ toEuro(lead.sale_value) }}</span>
                </span>
                <span
                  v-if="lead.monthly_sales_value"
                  class="p-d-flex p-jc-between p-mt-1"
                >
                  <span>Mensalidade</span
                  ><span>{{ toEuro(lead.monthly_sales_value) }}</span>
                </span>
                <span v-if="lead.cost" class="p-d-flex p-jc-between p-mt-1">
                  <span>Custo</span><span>{{ toEuro(lead.cost) }}</span>
                </span>
                <divider
                  v-if="
                    lead.sale_value || lead.monthly_sales_value || lead.cost
                  "
                />
              </div>
              <div
                v-if="lead.nextContact"
                class="p-mt-1"
                v-tooltip="'Póximo Contacto'"
              >
                <i class="pi pi-phone"></i>
                {{ lead.nextContact ? getDateTime(lead.nextContact) : "" }}
              </div>
              <div v-if="can('SeeAllCrmProposals')" class="p-mt-1">
                <i class="pi pi-user"></i> {{ lead.seller_name }}
              </div>
              <div class="p-mt-1">
                <i class="pi pi-calendar"></i> {{ lead.createdDaysAgo }} dias
              </div>
              <div class="p-d-flex p-jc-end">
                <Button
                  icon="pi pi-eye"
                  class="p-button-rounded p-button-text"
                  @click="viewLead(lead.id)"
                />
              </div>
            </div>
          </div>
        </Draggable>
      </div>
    </div>
  </div>
</template>

<script>
import { getOnlyDate, getOnlyTime, formatNumber } from "../helpers/helpers";
import crmService from "../services/crm.service";
import Draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "CrmTrello",
  components: {
    Draggable,
    Loading,
  },
  data() {
    return {
      leadsStatus: [],
      leadsStatusFilters: [],
      loading: false,
      subjectSearch: null,
      sellerSearch: null,
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  async beforeCreate() {},
  async created() {
    this.loading = true;
    await this.init();
    this.loading = false;
  },
  methods: {
    async init() {
      this.leadsStatus = [
        {
          backgroudColor: "#5F78DA",
          textColor: "#000",
          title: "Por Classificar",
          leads: await this.getByStatus(1),
          index: 1,
        },
        {
          backgroudColor: "#00C8B6",
          textColor: "#000",
          title: "Contactar Cliente",
          leads: await this.getByStatus(2),
          index: 2,
        },
        {
          backgroudColor: "#dcf900",
          textColor: "#000",
          title: "Reunião Marcada",
          leads: await this.getByStatus(9),
          index: 9,
        },
        {
          backgroudColor: "#FCC200",
          textColor: "#000",
          title: "Espera Resposta",
          leads: await this.getByStatus(3),
          index: 3,
        },
        {
          backgroudColor: "#86e053",
          textColor: "#000",
          title: "Vendido Sem PO",
          leads: await this.getByStatus(6),
          index: 6,
        },
        {
          backgroudColor: "#56dd54",
          textColor: "#000",
          title: "Projeto",
          leads: await this.getByStatus(8),
          index: 8,
        },
        {
          backgroudColor: "#e03d23",
          textColor: "#000",
          title: "Não Vendido",
          leads: await this.getByStatus(7),
          index: 7,
        },
        {
          backgroudColor: "#855fd8",
          textColor: "#000",
          title: "Piloto",
          leads: await this.getByStatus(5),
          index: 5,
        },
      ];
      this.leadsStatusFilters = [...this.leadsStatus];
      return (this.loading = false);
    },
    async getByStatus(status) {
      this.loading = true;
      let statusProposal = await crmService.getByStatus(status);
      statusProposal = statusProposal.map((v) => ({
        ...v,
        created_at: new Date(`${v.created_at}`),
        updated_at: new Date(`${v.updated_at}`),
        client_name: v.client ? v.client.name : null,
        seller_name: v.seller ? v.seller.name : null,
        type_name: v.type ? v.type.name : null,
        contactName: v.contact_id
          ? v.contact.name
          : `${
              v.potential_client_name ? v.potential_client_name + " | " : ""
            } ${v.potential_client_person ? v.potential_client_person : ""}`,
        nextContact: v.next_contact ? new Date(`${v.next_contact}`) : null,
      }));
      this.loading = false;
      return statusProposal;
    },
    getGroupheaderColor(index) {
      let statusInfo = this.leadsStatus.find((status) => status.index == index);
      if (!statusInfo) {
        return {};
      }
      return {
        backgroundColor: statusInfo.backgroudColor,
        borderColor: statusInfo.backgroudColor,
        color: statusInfo.textColor,
      };
    },
    hexToRgba(hex) {
      const alpha = 0.2;
      let strippedHex = hex.replace("#", "");
      if (strippedHex.length === 3) {
        strippedHex = strippedHex
          .split("")
          .map((char) => char + char)
          .join("");
      }
      const r = parseInt(strippedHex.substring(0, 2), 16);
      const g = parseInt(strippedHex.substring(2, 4), 16);
      const b = parseInt(strippedHex.substring(4, 6), 16);
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    },
    getGroupBackgroudColor(index) {
      let statusInfo = this.leadsStatus.find((status) => status.index == index);
      if (!statusInfo) {
        return {};
      }
      return {
        backgroundColor: this.hexToRgba(statusInfo.backgroudColor),
        borderColor: statusInfo.backgroudColor,
        color: statusInfo.textColor,
      };
    },
    getIconColor(info) {
      let statusInfo = this.leadsStatus.find((status) => status.title == info);
      return { color: statusInfo.backgroudColor };
    },
    viewLead(proposalId) {
      let route = this.$router.resolve({
        path: `/crm/lead/${proposalId}`,
      });
      window.open(route.href, "_blank");
    },
    getDateTime(date) {
      return `${getOnlyDate(date)} ${getOnlyTime(date)}`;
    },
    getDate(date) {
      return `${getOnlyDate(date)}`;
    },
    onMovedEnd(info) {
      crmService
        .setStatus(parseInt(info.item.id), { status_id: parseInt(info.to.id) })
        .then((response) => {
          if (!response) {
            this.$toast.add({
              severity: "error",
              summary: "Erro ao alterar o estado da lead",
              detail: "",
              life: 3000,
            });
            return;
          }
        });
    },
    searchBySubject(callBackFilter) {
      if (this.sellerSearch == null || this.sellerSearch.trim() == "") {
        this.leadsStatusFilters = this.leadsStatus.map((status) => ({
          ...status,
          leads: [...status.leads],
        }));
      }

      if (this.subjectSearch == null || this.subjectSearch.trim() == "") {
        if (callBackFilter != undefined && callBackFilter) {
          return this.filter("searchBySubject");
        }
        return;
      }
      this.leadsStatusFilters.forEach((status) => {
        status.leads = status.leads.filter((lead) =>
          lead.cleanSubject
            .toLowerCase()
            .includes(this.subjectSearch.toLowerCase())
        );
      });
      if (callBackFilter != undefined && callBackFilter) {
        return this.filter("searchBySubject");
      }

      return;
    },
    searchBySeller(callBackFilter) {
      if (this.subjectSearch == null || this.subjectSearch.trim() == "") {
        this.leadsStatusFilters = this.leadsStatus.map((status) => ({
          ...status,
          leads: [...status.leads],
        }));
      }
      if (this.sellerSearch == null || this.sellerSearch.trim() == "") {
        if (callBackFilter != undefined && callBackFilter) {
          return this.filter("searchBySeller");
        }
        return;
      }
      this.leadsStatusFilters.map((status) => {
        status.leads = status.leads.filter(
          (lead) =>
            lead.seller_name &&
            lead.seller_name
              .toLowerCase()
              .includes(this.sellerSearch.toLowerCase())
        );
      });
      if (callBackFilter != undefined && callBackFilter) {
        return this.filter("searchBySeller");
      }
      return;
    },
    filter(from) {
      if (
        (this.subjectSearch == null || this.subjectSearch.trim() == "") &&
        (this.sellerSearch == null || this.sellerSearch.trim() == "")
      ) {
        this.leadsStatusFilters = this.leadsStatus.map((status) => ({
          ...status,
          leads: [...status.leads],
        }));
      } else if (
        from != "searchBySeller" &&
        (this.subjectSearch == null || this.subjectSearch.trim() == "") &&
        this.sellerSearch != null &&
        this.sellerSearch.trim() != ""
      ) {
        this.searchBySeller(false);
      } else if (
        from != "searchBySubject" &&
        this.subjectSearch != null &&
        this.subjectSearch.trim() != "" &&
        (this.sellerSearch == null || this.sellerSearch.trim() == "")
      ) {
        this.searchBySubject(false);
      }
    },
    sumSales(leads) {
      return leads.reduce((acc, lead) => acc + lead.sale_value, 0);
    },
    sumMonthly(leads) {
      return leads.reduce((acc, lead) => acc + lead.monthly_sales_value, 0);
    },
    sumCost(leads) {
      return leads.reduce((acc, lead) => acc + lead.cost, 0);
    },
    toEuro(value) {
      return `${formatNumber(value)} €`;
    },
  },
};
</script>
<style>
.clickable-item {
  cursor: pointer;
  color: #000;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}

.main {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.status-list {
  /* width: 100%; */
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 260px;
  /* margin: 0 10px;  */
}

.status-header {
  height: 35px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 20px;
  margin: 0 2px;
  opacity: 1;
}

.trello-item {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  min-width: 250px;
}

.list-group {
  height: 100%;
}
</style>
