<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="'Criar Nova Lead'"
      :modal="true"
      :closable="false"
    >
      <form
        name="crmPcreateLead"
        class="p-col-12"
        @submit.prevent="setStatus"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-12">
            <span class="p-float-label">
              <InputText
                type="subject"
                name="subject"
                v-model="lead.subject"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('subject') },
                  'form-control',
                ]"
              />
              <label for="proposalStatus">Assunto</label>
            </span>
            <small v-if="errors.has('subject')" class="p-error" role="alert">
              Assunto é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-3">
            <span class="p-float-label">
              <Textarea
                :autoResize="true"
                rows="8"
                name="description"
                v-model="lead.description"
                v-bind:class="[
                  { 'p-invalid': errors.has('description') },
                  'form-control',
                ]"
              />
              <label for="proposalStatus">Descrição</label>
            </span>
            <small
              v-if="errors.has('description')"
              class="p-error"
              role="alert"
            >
              Descrição é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <Dropdown
                name="status"
                :options="statusList"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'id'"
                v-model="lead.status"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('status') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="status">Estado da Lead</label>
            </span>
            <small v-if="errors.has('status')" class="p-error" role="alert">
              Estado é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <Dropdown
                name="priority"
                :options="priorityList"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'priority'"
                v-model="lead.priority"
                v-bind:class="['form-control']"
              >
              </Dropdown>
              <label for="status">Prioridade da Lead</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <Dropdown
                name="category"
                :options="categoryList"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'id'"
                v-model="lead.category"
                v-bind:class="['form-control']"
              >
              </Dropdown>
              <label for="status">Categoria da Lead</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <Dropdown
                name="category"
                :options="closingProbabilityList"
                :filter="true"
                :optionLabel="'label'"
                :optionValue="'value'"
                v-model="lead.closing_probability"
                v-bind:class="['form-control']"
              >
              </Dropdown>
              <label for="status">Probabilidade de Fecho</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-3">
            <span class="p-float-label">
              <Dropdown
                name="client_type"
                :options="clientTypeList"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'id'"
                v-model="lead.client_type"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('client_type') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="client_type">Tipo de Cliente</label>
            </span>
            <small
              v-if="errors.has('client_type')"
              class="p-error"
              role="alert"
            >
              Tipo de Cliente é obrigatório
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-12 p-mt-3"
            v-if="lead.client_type > 1"
          >
            <span class="p-float-label">
              <Dropdown
                name="client_type"
                :options="contactsList"
                :filter="true"
                optionValue="id"
                dataKey="reference"
                v-model="lead.contact_id"
                :optionLabel="contactsListLabel"
                v-validate="lead.client_type > 1 ? 'required' : ''"
                v-bind:class="[
                  { 'p-invalid': errors.has('client_type') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="client_type">Contacto do Parceiro</label>
            </span>
            <small
              v-if="errors.has('client_type')"
              class="p-error"
              role="alert"
            >
              Contacto do Parceiro é obrigatório
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-12 p-mt-3"
            v-if="lead.client_type == 1"
          >
            <span class="p-float-label">
              <inputText
                name="client_type"
                v-model="lead.potential_client_name"
                v-validate="lead.client_type == 1 ? 'required' : ''"
                v-bind:class="[
                  { 'p-invalid': errors.has('client_type') },
                  'form-control',
                ]"
              >
              </inputText>
              <label for="client_type">Nome do Potencial Cliente</label>
            </span>
            <small
              v-if="errors.has('client_type')"
              class="p-error"
              role="alert"
            >
              Nome do Potencial Cliente é obrigatório
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-12 p-mt-3"
            v-if="lead.client_type == 1"
          >
            <span class="p-float-label">
              <inputText
                name="potential_client_person"
                v-model="lead.potential_client_person"
                v-bind:class="[
                  { 'p-invalid': errors.has('potential_client_person') },
                  'form-control',
                ]"
              >
              </inputText>
              <label for="potential_client_person"
                >Representante do Potencial Cliente</label
              >
            </span>
            <small
              v-if="errors.has('potential_client_person')"
              class="p-error"
              role="alert"
            >
              Representante do Potencial Cliente é obrigatório
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-12 p-mt-3"
            v-if="lead.client_type == 1"
          >
            <span class="p-float-label">
              <inputText
                name="potential_client_telephone"
                v-model="lead.potential_client_telephone"
                v-bind:class="[
                  { 'p-invalid': errors.has('potential_client_telephone') },
                  'form-control',
                ]"
              >
              </inputText>
              <label for="potential_client_telephone"
                >Telefone do Potencial Cliente</label
              >
            </span>
            <small
              v-if="errors.has('potential_client_telephone')"
              class="p-error"
              role="alert"
            >
              Telefone do Representante do Potencial Cliente é obrigatório
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-12 p-mt-3"
            v-if="lead.client_type == 1"
          >
            <span class="p-float-label">
              <inputText
                name="potential_client_email"
                v-model="lead.potential_client_email"
                v-bind:class="[
                  { 'p-invalid': errors.has('potential_client_email') },
                  'form-control',
                ]"
              >
              </inputText>
              <label for="potential_client_email"
                >Email do Potencial Cliente</label
              >
            </span>
            <small
              v-if="errors.has('potential_client_email')"
              class="p-error"
              role="alert"
            >
              Email do epresentante do Potencial Cliente é obrigatório
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-12 p-mt-3"
            v-if="lead.client_type == 1"
          >
            <span class="p-float-label">
              <Textarea
                :autoResize="true"
                rows="3"
                name="potential_client_comments"
                v-model="lead.potential_client_comments"
                v-bind:class="[
                  { 'p-invalid': errors.has('potential_client_comments') },
                  'form-control',
                ]"
              >
              </Textarea>
              <label for="potential_client_comments"
                >Comentários do Potencial Cliente</label
              >
            </span>
            <small
              v-if="errors.has('potential_client_comments')"
              class="p-error"
              role="alert"
            >
              Comentários do Potencial Cliente é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-3">
            <span class="p-float-label">
              <Textarea
                :autoResize="true"
                rows="3"
                name="competitors"
                v-model="lead.competitors"
                v-bind:class="[
                  { 'p-invalid': errors.has('competitors') },
                  'form-control',
                ]"
              >
              </Textarea>
              <label for="competitors">Concorrência</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <InputNumber
                mode="currency"
                currency="EUR"
                locale="pt-PT"
                name="sale_value"
                v-model="lead.sale_value"
                v-bind:class="[
                  { 'p-invalid': errors.has('sale_value') },
                  'form-control',
                ]"
              >
              </InputNumber>
              <label for="sale_value">Valor Venda</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <InputNumber
                mode="currency"
                currency="EUR"
                locale="pt-PT"
                name="monthly_sales_value"
                v-model="lead.monthly_sales_value"
                v-bind:class="[
                  { 'p-invalid': errors.has('monthly_sales_value') },
                  'form-control',
                ]"
              >
              </InputNumber>
              <label for="monthly_sales_value">Valor Mensalidade</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <InputNumber
                mode="currency"
                currency="EUR"
                locale="pt-PT"
                name="cost"
                v-model="lead.cost"
                v-bind:class="[
                  { 'p-invalid': errors.has('cost') },
                  'form-control',
                ]"
              >
              </InputNumber>
              <label for="cost">Preço Custo</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3"></div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <v-date-picker
              v-model="lead.proposal_date"
              :masks="{
                input: 'DD-MM-YYYY',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <span class="p-float-label">
                  <input
                    v-bind:class="[
                      'form-control',
                      'p-inputtext',
                      'p-component',
                      'p-filled',
                    ]"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                  <label for="inputtext">Data Proposta</label>
                </span>
              </template>
            </v-date-picker>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <v-date-picker
              v-model="lead.expected_sale_date"
              :masks="{
                input: 'DD-MM-YYYY',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <span class="p-float-label">
                  <input
                    v-bind:class="[
                      'form-control',
                      'p-inputtext',
                      'p-component',
                      'p-filled',
                    ]"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                  <label for="inputtext">Data Prevista da Venda</label>
                </span>
              </template>
            </v-date-picker>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <v-date-picker
              v-model="lead.expected_installation_date"
              :masks="{
                input: 'DD-MM-YYYY',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <span class="p-float-label">
                  <input
                    v-bind:class="[
                      'form-control',
                      'p-inputtext',
                      'p-component',
                      'p-filled',
                    ]"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                  <label for="inputtext">Data Prevista da Instalação</label>
                </span>
              </template>
            </v-date-picker>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <v-date-picker
              v-model="lead.next_contact"
              mode="dateTime"
              :min-date="new Date()"
              is24hr
              :masks="{
                input: 'DD-MM-YYYY',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <span class="p-float-label">
                  <input
                    v-bind:class="[
                      'form-control',
                      'p-inputtext',
                      'p-component',
                      'p-filled',
                    ]"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                  <label for="inputtext">Próximo Contato</label>
                </span>
              </template>
            </v-date-picker>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="createLead"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import { getOnlyDate, getOnlyTime } from "../../helpers/helpers";
import crmService from "../../services/crm.service";
import externalManagerService from "../../services/externalManager.service";
export default {
  name: "LeadForm",
  components: {},
  props: ["show"],
  watch: {
    statusId() {
      this.showLoader = false;
      this.$validator.pause();
      this.$validator.reset();
    },
  },
  data() {
    return {
      statusList: [],
      contactsList: [],
      clientTypeList: [],
      priorityList: [],
      categoryList: [],
      closingProbabilityList: [
        { label: "0%", value: 0 },
        { label: "15%", value: 15 },
        { label: "25%", value: 25 },
        { label: "50%", value: 50 },
        { label: "75%", value: 75 },
        { label: "90%", value: 90 },
        { label: "100%", value: 100 },
      ],
      showLoader: false,
      lead: {
        status: null,
        subject: null,
        client_type: null,
        contact_id: null,
        potential_client_name: null,
        potential_client_person: null,
        potential_client_telephone: null,
        potential_client_email: null,
        potential_client_comments: null,
        competitors: null,
        category: null,
        sale_value: null,
        monthly_sales_value: null,
        cost: null,
        proposal_date: null,
        expected_sale_date: null,
        expected_installation_date: null,
        priority: null,
        closing_probability: null,
        next_contact: null,
      },
    };
  },
  async created() {
    this.getStatusList();
    this.contactsList = await externalManagerService.getAll();
    this.clientTypeList = await crmService.getClientTypeList();
    this.priorityList = await crmService.getPriorityList();
    this.categoryList = await crmService.getProposalsCategories();
  },
  methods: {
    reset() {
      this.lead = {
        status: null,
        subject: null,
        client_type: null,
        contact_id: null,
        potential_client_name: null,
        potential_client_person: null,
        potential_client_telephone: null,
        potential_client_email: null,
        potential_client_comments: null,
        competitors: null,
        category: null,
        sale_value: null,
        monthly_sales_value: null,
        cost: null,
        proposal_date: null,
        expected_sale_date: null,
        expected_installation_date: null,
        priority: null,
        closing_probability: null,
        next_contact: null,
      };
    },
    getStatusList() {
      return crmService
        .getStatusList()
        .then((response) => (this.statusList = response));
    },
    cancel() {
      this.reset();
      return this.$emit("cancel");
    },
    createLead() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        let params = {};
        params.subject = this.lead.subject;
        if (this.lead.status) {
          params.status = parseInt(this.lead.status);
        }

        if (this.lead.client_type) {
          params.client_type = parseInt(this.lead.client_type);

          if (this.lead.client_type > 1 && this.lead.contact_id) {
            params.contact_id = this.lead.contact_id;
          } else if (this.lead.client_type == 1) {
            if (this.lead.potential_client_name) {
              params.potential_client_name = this.lead.potential_client_name;
            }
            if (this.lead.potential_client_person) {
              params.potential_client_person =
                this.lead.potential_client_person;
            }
            if (this.lead.potential_client_telephone) {
              params.potential_client_telephone =
                this.lead.potential_client_telephone;
            }
            if (this.lead.potential_client_email) {
              params.potential_client_email = this.lead.potential_client_email;
            }
            if (this.lead.potential_client_comments) {
              params.potential_client_comments =
                this.lead.potential_client_comments;
            }
            if (this.lead.competitors) {
              params.competitors = this.lead.competitors;
            }
          }
        }

        if (this.lead.description) {
          params.description = this.lead.description;
        }

        if (this.lead.sale_value) {
          params.sale_value = this.lead.sale_value;
        }
        if (this.lead.monthly_sales_value) {
          params.monthly_sales_value = this.lead.monthly_sales_value;
        }
        if (this.lead.cost) {
          params.cost = this.lead.cost;
        }

        if (this.lead.proposal_date) {
          params.proposal_date = `${getOnlyDate(this.lead.proposal_date)}`;
        }

        if (this.lead.expected_sale_date) {
          params.expected_sale_date = `${getOnlyDate(
            this.lead.expected_sale_date
          )}`;
        }

        if (this.lead.expected_installation_date) {
          params.expected_installation_date = `${getOnlyDate(
            this.lead.expected_installation_date
          )}`;
        }

        if (this.lead.next_contact) {
          params.next_contact = `${getOnlyDate(
            this.lead.next_contact
          )} ${getOnlyTime(this.lead.next_contact)}`;
        }
        if (this.lead.closing_probability) {
          params.closing_probability = parseInt(this.lead.closing_probability);
        }
        if (this.lead.category) {
          params.category = parseInt(this.lead.category);
        }

        return crmService.create(params).then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao criar a lead",
              detail: "",
              life: 3000,
            });
          }
          this.$emit("createdLead", response);
          return this.$toast.add({
            severity: "success",
            summary: `Lead criada com sucesso`,
            detail: ``,
            life: 3000,
          });
        });
      });
    },
    contactsListLabel(item) {
      let label = `${item.name}`;
      if (item.email != "") {
        label += ` | ${item.email}`;
      }
      if (item.telephone != "") {
        label += ` | ${item.telephone.replace(" ", "")}`;
      }
      if (item.type != "") {
        label += ` | ${item.type}`;
      }
      return label;
    },
  },
};
</script>
